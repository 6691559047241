import React, { createContext, useState } from 'react'

const SoundsContext = createContext(undefined)
const SoundsDispatchContext = createContext(undefined)

function SoundsProvider({children}) {

  // Context state
  const [sounds, setSounds] = useState({
    enabled: true
  })

  return (
    <SoundsContext.Provider value={sounds}>
      <SoundsDispatchContext.Provider value={setSounds}>
        {children}
      </SoundsDispatchContext.Provider>
    </SoundsContext.Provider>
  )
}

export {
  SoundsProvider,
  SoundsContext,
  SoundsDispatchContext
};
