/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//
import React from "react"
import { SoundsProvider } from "./src/context/SoundsContext"
import { CookieConsentProvider } from "./src/context/CookieContext"
import { Cookies } from "react-cookie"

export const wrapRootElement = ({ element }) => (
  <SoundsProvider>
    <CookieConsentProvider>
      {element}
    </CookieConsentProvider>
  </SoundsProvider>
)

export const onRouteUpdate = (_, pluginOptions) => {
  const cookies = new Cookies()

  const { performance, marketing } = cookies.get("consent") || {
    isSet: false,
    performance: false,
    marketing: false,
  }
  window.dataLayer.push({
    consent_performance: performance,
    consent_marketing: marketing,
  })
}
